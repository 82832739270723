let Herbal = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient;
  let ServiceBaseAddress = serviceAddr;

  /**
   * 获取中药方剂搜索列表数据
   * @param {any} success
   * @param {any} error
   */
  this.Herbals = function (params, success, error) {
    var url = ServiceBaseAddress + "/api/HerbalPrescription/Search" + params;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取中药方剂热词数据
   * @param {any} success
   * @param {any} error
   */
  this.Hotspot = function (success, error) {
    var url = ServiceBaseAddress + "/api/HerbalPrescription/Hotspot";
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取中药方剂分类
   * @param {any} success
   * @param {any} error
   */
  this.Categorys = function (success, error) {
    var url = ServiceBaseAddress + "/api/HerbalPrescription/Categories";
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取中药方剂详情数据
   * @param id
   * @param searchId
   * @param keyWord
   * @param success
   * @param error
   * @constructor
   */
  this.Detail = function (hashKey, success, error) {
    var url = ServiceBaseAddress + "/api/HerbalPrescription/" + hashKey;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 配伍
   * @param id
   * @param searchId
   * @param keyWord
   * @param success
   * @param error
   * @constructor
   */
  //   this.compatibility = function (name, success, error) {
  //     var url = ServiceBaseAddress + "/api/Compatibility/PatientPrescription?compositionName=" + name;
  //     TokenClient.Post(
  //       url,
  //       true,
  //       { "Content-Type": "application/json" },
  //       null,
  //       function (data) {
  //         if (success) {
  //           var jsonObj = JSON.parse(data);
  //           success(jsonObj);
  //         }
  //       },
  //       error
  //     );
  //   };

  this.compatibility = function (items, success, error) {
    // var url = ServiceBaseAddress + '/api/Compatibility';
    var url = ServiceBaseAddress + "/api/Compatibility/Query";
    var params = {
      items: items,
      types: [0],
    };
    TokenClient.Post(
      url,
      false,
      { "Content-Type": "application/json" },
      params,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
};

export { Herbal };
