<template>
    <div class="zhong">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <h3>中药方剂</h3>
                <p>ZHONG YAO FANG JI</p>
            </div>
        </div>
        <div class="content">
            <div class="content-box clearfix">
                <div class="content-left">
                    <div class="hot-search">
                        <p>热门搜索</p>
                        <ul class="clearfix">
                            <li v-for="hotsearch in hots.slice(0, 6)" :key="hotsearch.index"
                                @click="toherbalDetail(hotsearch.hashKey)">
                                <span>{{ hotsearch.herbalPrescriptionName }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="sidenav">
                        <h3 @click="searchherbal(0)">全部方剂</h3>
                        <el-menu class="el-menu-vertical-demo" @open="handleOpen" unique-opened>
                            <template>
                                <el-submenu v-for="(sideNav,index) in herbalNavList" :key="index" :index="index + ''">
                                    <template slot="title">
                                        <span>{{ sideNav.categoryName }}</span>
                                    </template>
                                    <div class="subtitle">
                                        <el-menu-item v-for="(subItem, i) in sideNav.children" :key="i"
                                            :index="subItem.categoryId + ''" @click="searchherbal(subItem.categoryId)">
                                            <template slot="title">
                                                {{ subItem.categoryName }}
                                            </template>
                                        </el-menu-item>
                                    </div>
                                </el-submenu>
                            </template>
                        </el-menu>
                    </div>
                </div>
                <div class="content-right">
                    <div class="result-tit">
                        <p>为您找到 <span>{{ total }}</span><em>个结果</em></p>
                    </div>
                    <ul class="food-list">
                        <li class="food-item" v-for="(herbalDetailItem, i) in herbalDetailList">
                            <div @click="toherbalDetail(herbalDetailItem.hashKey)">
                                <h3 class="food-tit">
                                    {{ herbalDetailItem.herbalPrescriptionName }}
                                    <span v-if="category(herbalDetailItem)">{{ category(herbalDetailItem) }}</span>
                                </h3>
                                <p v-if="herbalDetailItem.source">出自： <em>{{ herbalDetailItem.source }}</em></p>
                            </div>
                            <div class="resule-star">
                                <span class="on-cpt" @click="onCompatibility(herbalDetailItem)">加入配伍栏</span>
                                <!-- <i class="el-icon-star-off" @click="onCollection(herbalDetailItem)"></i> -->
                            </div>
                        </li>
                    </ul>
                    <div id="page">
                        <el-pagination background layout="prev, pager, next,total" :page-size="15"
                            @current-change="handleCurrentChange" :current-page.sync="currentPage" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>

import { Herbal } from '../../components/Drug/Herbal'
import hdCom from '../../components/header'
import ftCom from '../../components/footer'
import rtBar from "../../components/right";
import { Collection } from "../../components/collection/Collection"
import { addCompare, getCompatibilityList } from '../../utils/Compatibility'
var searchId = 0
var pageIndex = 1
var categoryId = 0
var keyword = ''
export default {
    name: "herbal",
    components: {
        hdCom,
        ftCom,
        rtBar
    },
    data() {
        var herbal = new Herbal(this.TokenClient, this.Services.Drug)
        var collection = new Collection(this.TokenClient, this.Services.Statics)
        return {
            checkedhot: [],
            hots: [],
            herbalNavList: [],
            herbalDetailList: [],
            total: 0,
            currentPage: 1, //初始页
            rloading: [], //loading遮罩
            herbalDomain: herbal,
            collectionDomain: collection
        }
    },
    mounted() {
        this.$bus.$emit('isShowChangeSize');
        this.$bus.$emit('select', 'herbal')
        this.herbalCategories()
        this.herbalHotspot()
        // categoryId = this.$route.query.categoryId
        keyword = this.$route.query.keyword
        this.searchherbal(categoryId,pageIndex)
    },
    methods: {
        handleOpen(key, keyPath) {

        },
        handleClose(key, keyPath) {

        },
        //左侧分类获取
        herbalCategories: function () {
            var _this = this;
            _this.herbalDomain.Categorys(function (data) {
                _this.herbalNavList = (data.data)
                _this.herbalNavList.forEach((item) => {
                    if (item.children && item.children.length > 0) {
                        item.children.unshift({
                            categoryId: item.categoryId,
                            categoryName: "全部"
                        })
                    } else {
                        item.children = [{
                            categoryId: item.categoryId,
                            categoryName: "全部"
                        }]
                    }
                })
            },
                function (error) {
                    console.log("左侧分类请求失败:", error)
                })
        },
        //热门搜索词获取
        herbalHotspot: function () {
            var _this = this;
            _this.herbalDomain.Hotspot(function (data) {
                _this.hots = data.data
            },
                function (error) {
                    console.log("热门搜索词请求失败:", error)
                })
        },
        searchherbal(cateId, isPage = false) {
            categoryId = cateId;
            var _this = this;
            _this.rloading = _this.openLoading()
            if (!isPage) pageIndex = 1
            if (cateId == undefined) cateId = 0
            let params = `/${cateId}/${pageIndex}`
            if (keyword != '' && keyword != undefined) {
                params = params + `/${keyword}`
            } else {
                params += '/%20';
            }
            _this.herbalDomain.Herbals(params, function (data) {
                _this.herbalDetailList = data.data.results
                _this.total = data.data.dataTotal
                categoryId = cateId;
                searchId = data.msg
                _this.rloading.close()
            },
                function (error) {
                    console.log("中药方剂请求失败:", error)
                })
            if (!isPage) {
                this.currentPage = 1
            }
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0
        },
        //右侧中药方剂属性获取
        category: function (data) {
            var categoryName = "";
            if (data.herbalPrescriptionCategory != null) {
                if (data.herbalPrescriptionCategory.parent != null) {
                    categoryName = data.herbalPrescriptionCategory.parent.categoryName + '/'
                }
                categoryName += data.herbalPrescriptionCategory.categoryName;
            }
            return categoryName
        },
        onCompatibility: function (item) {
            var compatibility = getCompatibilityList();
            if (compatibility.length > 0) {
                if (compatibility.some(i => i.hashKey == item.hashKey && i.type == '2')) {
                    this.$message.error('已加入用药审查！');
                    return false;
                }
            }
            addCompare(item.hashKey, item.herbalPrescriptionName, 1, "herbal");
            this.$message.success('成功加入用药审查！');
            this.$bus.$emit('right', 'reload')
        },
        //收藏
        onCollection: function (item) {
            var _this = this;
            _this.collectionDomain.Put("herbal", item.herbalPrescriptionName, item.hashKey, item.herbalPrescriptionId, 0,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '收藏成功!'
                    });
                }, function (err) {
                    if (err.status === 400) {
                        _this.$message.error('请勿重复收藏');
                    }
                });
        },
        //分页点击事件
        handleCurrentChange: function (pageNum) {
            this.currentPage = pageNum
            pageIndex = pageNum
            this.searchherbal(categoryId, true);
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0
        },
        //跳转到详情
        toherbalDetail(hashKey) {
            this.$router.push({
                path: '/Drug/herbalDetail',
                //name:'herbalDetail',
                query: {
                    hashKey: hashKey,
                }
            })
        }
    },
    watch: {
        $route() {
            keyword = this.$route.query.keyword
            this.searchherbal(categoryId,pageIndex);
        }
    }
}
</script>

<style scoped>
@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";
@import "../../assets/css/zhongyaoItem.css";
.zhong .food-list .food-item {
    position: relative;
    padding: 15px 20px;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.zhong .food-list .food-item:hover {
    box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.2);
}

.zhong .food-tit {
    font-size: 18px;
    font-weight: normal;
}

.zhong .food-tit span {
    font-size: 12px !important;
    color: #fff;
    background-color: #b76c12;
    border-radius: 10px;
    height: 18px;
    line-height: 18px;
    margin-left: 10px;
    padding: 0 4px;
    display: inline-block;
    position: relative;
    top: -2px;
}

.zhong .food-item p {
    margin-top: 10px;
    color: #666666;
}

.zhong .food-item p em {
    font-style: normal;
}
.el-submenu .el-menu-item {
    height: 36px;
    line-height: 36px;
}
.sc-btn i {
    position: absolute;
    right: 43px;
    top: 15px;
    font-size: 26px;
    color: #b76c12;
    height: 36px;
    line-height: 36px;
    display: block;
    padding: 0 20px;
}
.food-item:hover .resule-star {
    opacity: 1;
    transition: all linear 0.5s;
}

.resule-star {
    position: absolute;
    z-index: 999;
    right: 43px;
    top: 15px;
    transition: all linear 0.5s;
    opacity: 0;
    /* margin-right: 30px; */
}
.resule-item:hover .resule-star {
    opacity: 1;
    transition: all linear 0.5s;
}
.sidenav /deep/ .el-submenu__title i {
    color: #ffffff;
}
.el-submenu__title * {
    margin-left: 10px;
}
.zhong .sidenav>h3 {
    width: 310px;
    padding-left: 10px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    color: #ffffff;
    background: #b76c12;
    font-weight: bold;
}
.zhong  /deep/ .el-submenu__title {
  padding: 0 !important;
  background: #b76c12;
  color: #ffffff;
  margin-top: 7px;
}
.sidenav /deep/ .el-submenu__title>span {
    font-size: 16px;
    font-weight: 700;
}
</style>
